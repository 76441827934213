import check from "../../../assets/images/check.png";

type ExpertProps = {
  expert: {
    short_description: string;
    channel_dp: string;
    calls: string;
    expert: {
      first_name: string;
      last_name: string;
      dp?: string;
      opigo_verified: boolean;
    };
    channel_package: {
      price: string;
    };
  };
};

export const ExpertViewCart = ({ expert }: ExpertProps) => {
  return (
    <div className="expert-card">
      <div style={{ height: "158px", width: "100%" }}>
        {expert.channel_dp && (
          <img
            src={expert.channel_dp}
            alt="Expert"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        )}
      </div>

      <div
        style={{
          height: "50%",
          padding: "0px 16px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <h2
          style={{
            fontSize: "16px",
            margin: "0px",
            display: "flex",
            alignItems: "center",
          }}
        >
          {expert.expert.dp && (
            <img
              src={expert.expert.dp}
              alt="DP"
              style={{
                borderRadius: "100%",
                width: "15px",
                height: "15px",
                marginRight: "5px",
              }}
            />
          )}
          {expert.expert.first_name} {expert.expert.last_name}
          {expert.expert.opigo_verified && (
            <img
              src={check}
              alt="Verified"
              style={{ marginLeft: "6px", height: "14px", width: "14px" }}
            />
          )}
        </h2>
        <p
          style={{
            fontSize: "14px",
            color: "#202020",
            marginTop: "10px",
            lineHeight: "22px",
          }}
        >
          {expert.short_description || "---"}
        </p>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "12px",
            marginTop: "auto",
            marginBottom: "16.5px",
          }}
        >
          {expert.calls && (
            <span
              style={{
                background: "#F1F3F6",
                padding: "5px 8px",
                borderRadius: "4px",
                color: "#6B6B6E",
              }}
            >
              {expert.calls}
            </span>
          )}

          {expert.channel_package.price && (
            <span
              style={{
                fontWeight: 500,
                color: "#202020",
                fontSize: "14px",
                textAlign: "right",
              }}
            >
              From ₹{expert.channel_package.price}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
