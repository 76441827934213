import { B2CLayout } from "@/components/Layout";
import { ExpertViewCart } from "./ExpertViewCart";
import axios from "axios";
import { useEffect, useState } from "react";
import storage from "@/utils/storage";
import "../../../styles/experts.scss";

type IExpert = {
  short_description: string;
  channel_dp: string;
  calls: string;
  expert: {
    first_name: string;
    last_name: string;
    dp?: string;
    opigo_verified: boolean;
  };
  channel_package: {
    price: string;
  };
};

function Experts() {
  const [tabs, setTabs] = useState<string[]>([]);
  const [experts, setExperts] = useState<IExpert[]>([]);
  const [currentTab, setCurrentTab] = useState<string>("");

  const getExpertsTab = async () => {
    const {
      data: { categories },
    } = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/channels/data/get_channel_categories`
    );
    setTabs(categories);
  };

  const getExperts = async (tab: string) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_API_URL}/channels/data/explore_channels?market_category=${tab}&page=1&per_page=10`
    );

    setExperts(data);
  };

  useEffect(() => {
    getExpertsTab();
  }, []);

  useEffect(() => {
    if (tabs.length) {
      setCurrentTab(tabs[0]);
      getExperts(tabs[0]);
    }
  }, [tabs]);

  return (
    <B2CLayout>
      <h2 className="top-experts-text">Discover Top Experts</h2>
      <div className="blank-space-container"></div>
      <div className="container">
        <div className="tabsContainer">
          {tabs.map((tab, index) => (
            <button
              className={`tabButton ${tab === currentTab ? "active" : ""}`}
              onClick={() => {
                setCurrentTab(tab);
                getExperts(tab);
              }}
              key={index}
            >
              {tab}
            </button>
          ))}
        </div>

        {experts.length > 0 && (
          <div className="cardsContainer">
            {experts.map((expert, index) => (
              <ExpertViewCart key={index} expert={expert} />
            ))}
          </div>
        )}
      </div>
    </B2CLayout>
  );
}

export default Experts;
